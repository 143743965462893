<template>
  <div class="row m-0 align-items-center justify-content-center relative">
    <div class="col-12 col-md-auto">
      <div class="row justify-content-md-center align-items-center">
        <div class="col-12 col-md-auto mb-4 mb-md-0">
          <div class="row h-100 align-items-center justify-content-center">
            <div class="col-auto col-md medium green-text text-end line1">
              FILTER<div class="d-none d-md-block">PROJECTS</div><span class="d-inline-block d-md-none">&nbsp;PROJECTS</span>
            </div>
            <div class="col-auto col-md-auto p-0">
              <IconFilter color="green" size="size28" class="d-none d-md-block" />
              <IconFilter color="green" size="size16" class="d-md-none" />
            </div>
          </div>
        </div>
        <div class="col-6 col-md-auto px-1" v-if="projectCategories.size > 0">
          <FilterSelect
            id="category"
            :options="projectCategories"
            placeholder="Project Category"
            @applyfilter="filterProjectCategory"
            @clearfilter="filterProjectCategoryClear"
            title="Filter Project Category"
          />
        </div>
        <div class="col-6 col-md-auto px-1" v-if="appealType.size > 0">
          <FilterSelect
            id="appealType"
            :options="appealType"
            placeholder="Appeal Type"
            @applyfilter="filterAppealType"
            @clearfilter="filterAppealTypeClear"
            title="Filter Appeal Type"
          />
        </div>
        <div class="col-6 col-md-auto px-1" v-if="projectCountries.size > 0">
          <FilterSelect
            id="country"
            :options="projectCountries"
            placeholder="Country"
            @applyfilter="filterCountry"
            @clearfilter="filterCountryClear"
            title="Filter Country"
            :filtered="countryFiltered"
          />
        </div>
        <div class="col-6 col-md-auto px-1">
          <FilterSelect
            id="years"
            :options="projectYears"
            placeholder="Date/Year"
            @applyfilter="filterYear"
            @clearfilter="filterYearClear"
            title="Filter Date/Year"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    FilterSelect: defineAsyncComponent(() => import('../components/FilterSelect.vue')),
    IconFilter: defineAsyncComponent(() => import('../components/icons/IconFilter.vue'))
  },
  name: 'Project Filter',
  props: ['filteredProjects', 'filteredByCountry'],
  computed: {
    countryFiltered () {
      if (this.filteredByCountry && this.filteredByCountry.length > 0) {
        return this.filteredByCountry
      }
      return false
    },
    projectCountries () {
      const arr = this.filteredProjects
      const result = new Map()
      arr.forEach(el => {
        const item = result.get(el.location.country.id) || { id: el.location.country.id, name: el.location.country.name, count: 0 }
        item.count++
        result.set(item.id, item)
      })
      const sortedAsc = new Map([...result].sort((a, b) => (a[1].name > b[1].name ? 1 : -1)))
      return sortedAsc
    },
    projectCategories () {
      const arr = this.filteredProjects
      const result = new Map()
      arr.forEach(el => {
        const item = result.get(el.projectType.id) || { id: el.projectType.id, name: el.projectType.name, count: 0 }
        item.count++
        result.set(item.id, item)
      })
      const sortedAsc = new Map([...result].sort((a, b) => (a[1].name > b[1].name ? 1 : -1)))
      return sortedAsc
    },
    projectYears () {
      const arr = this.filteredProjects
      const result = new Map()
      arr.forEach(el => {
        const dateYear = DateTime.fromMillis(el.uiDate).toFormat('yyyy')
        const item = result.get(dateYear) || { id: dateYear, name: dateYear, count: 0 }
        item.count++
        result.set(item.id, item)
      })
      const sortedAsc = new Map([...result].sort((a, b) => (a[1].name > b[1].name ? 1 : -1)))
      return sortedAsc
    },
    appealType  () {
      const arr = this.filteredProjects
      const result = new Map()
      arr.forEach(el => {
        if (el.esaaleSawaab) {
          const item = result.get('esaaleSawaab') || { id: 'esaaleSawaab', name: 'Esaal-e-Sawaab', count: 0 }
          item.count++
          result.set(item.id, item)
        }
        if (el.giftOptions) {
          const item = result.get('giftOptions') || { id: 'giftOptions', name: 'Gift Options', count: 0 }
          item.count++
          result.set(item.id, item)
        }
        if (el.sadaqahJaariyah) {
          const item = result.get('sadaqahJaariyah') || { id: 'sadaqahJaariyah', name: 'Sadaqah Jaariyah', count: 0 }
          item.count++
          result.set(item.id, item)
        }
        if (el.section18ATAX) {
          const item = result.get('section18ATAX') || { id: 'section18ATAX', name: 'Section 18A', count: 0 }
          item.count++
          result.set(item.id, item)
        }
      })
      const sortedAsc = new Map([...result].sort((a, b) => (a[1].name > b[1].name ? 1 : -1)))
      return sortedAsc
    }
  },
  methods: {
    filterCountry (val) {
      if (val.length > 0) {
        this.$emit('updateCountry', val)
      } else {
        this.$emit('updateCountry', null)
      }
    },
    filterCountryClear () {
      this.$emit('updateCountry', null)
    },
    filterProjectCategory (val) {
      if (val.length > 0) {
        this.$emit('updateCategory', val)
      } else {
        this.$emit('updateCategory', null)
      }
    },
    filterProjectCategoryClear () {
      this.$emit('updateCategory', null)
    },
    filterYear (val) {
      if (val.length > 0) {
        this.$emit('updateYear', val)
      } else {
        this.$emit('updateYear', null)
      }
    },
    filterYearClear () {
      this.$emit('updateYear', null)
    },
    filterAppealType (val) {
      if (val.length > 0) {
        this.$emit('updateAppealType', val)
      } else {
        this.$emit('updateAppealType', null)
      }
    },
    filterAppealTypeClear () {
      this.$emit('updateAppealType', null)
    }
  }
}
</script>

<style scoped>
.line1 {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--green-color);
  line-height: 1;
}
</style>
